import reactIcon from '../../assets/images/icons/languages/react.svg';
import typescriptIcon from '../../assets/images/icons/languages/typescript.svg';
import javascriptIcon from '../../assets/images/icons/languages/javascript.svg';
import styledIcon from '../../assets/images/icons/languages/styled-components.svg';
import nextjsIcon from '../../assets/images/icons/languages/nextjs.svg';
import bffIcon from '../../assets/images/icons/languages/bff.svg';
import restqlIcon from '../../assets/images/icons/languages/restql.svg';
import jestIcon from '../../assets/images/icons/languages/jest.svg';

export const languages = [
  {
    id: 0,
    name: 'React',
    image: `<img src="${reactIcon}" alt="react" />`,
    time: 'Conhecimento avançado, sendo utilizado desde 2017.',
  },
  {
    id: 1,
    name: 'Typescript',
    image: `<img src="${typescriptIcon}" alt="typescript" />`,
    time: 'Conhecimento avançado, sendo utilizado desde 2019.',
  },
  {
    id: 2,
    name: 'Javascript',
    image: `<img src="${javascriptIcon}" alt="javascript" />`,
    time: 'Conhecimento avançado, sendo utilizado desde 2017.',
  },
  {
    id: 3,
    name: 'Styled-components',
    image: `<img src="${styledIcon}" alt="styled" />`,
    time: 'Conhecimento avançado, sendo utilizado desde 2019.',
  },
  {
    id: 4,
    name: 'Next.js',
    image: `<img src="${nextjsIcon}" alt="next" />`,
    time: 'Iniciante, sendo utilizado desde 2022.',
  },
  {
    id: 5,
    name: 'Back-end for front-end',
    image: `<img src="${bffIcon}" alt="bff" />`,
    time: 'Iniciante, sendo utilizado desde 2022.',
  },
  {
    id: 6,
    name: 'RestQL',
    image: `<img src="${restqlIcon}" alt="restql" />`,
    time: 'Iniciante, sendo utilizado desde 2022.',
  },
  {
    id: 7,
    name: 'Jest',
    image: `<img src="${jestIcon}" alt="jest" />`,
    time: 'Iniciante, sendo utilizado desde 2022.',
  },
];

export default languages;
