export const colors = {
  darkMode: {
    font: '#fff',
    background: '#101010',
    header: 'rgba(0, 0, 0, 0.70)',
    switch: '#585858',
    hover: '#aeaeae',
    neutral: '#161616',
    footer: '#000',
    stroke: '#373537',
  },
  whiteMode: {
    font: '#000',
    background: '#e7e7e7',
    header: 'rgba(255, 255, 255, 0.70)',
    switch: '#fff',
    hover: '#ddd',
    neutral: '#fff',
    footer: '#fff',
    stroke: '#d9d9d9',
  },
  default: {
    pink: '#d94766',
    hoverPink: '#C62D4E',
    shadow: 'rgba(217, 71, 102, 0.33)',
  },
};
