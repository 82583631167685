export const experiences = [
  {
    id: 0,
    company: 'Americanas SA',
    period: '09/2021 - Atual',
    role: 'Desenvolvedora Front-end',
    description:
      'Desenvolvimento Front-end em aplicações B2B e B2C nos projetos de fluxo de pagamento e minha conta.',
    tools:
      'HTML, CSS, React, Next.js, BFF, RestQL, JavaScript, TypeScript, Styled-components, Storybook, jest, cypress.',
  },
  {
    id: 1,
    company: 'Ioasys',
    period: '10/2019 - 08/2021',
    role: 'Desenvolvedora Front-end',
    description:
      'Desenvolvimento Front-end com participação do comitê de front-end e mentoria de novos funcionários ensinando e auxiliando no que fosse necessário.',
    tools:
      'HTML, CSS, React, Redux, JavaScript, TypeScript, Styled-components, Storybook, testing-library.',
  },
  {
    id: 2,
    company: 'GetInfo',
    period: '09/2018 - 09/2019',
    role: 'Desenvolvedora Front-end',
    description:
      'Desenvolvimento de aplicações web e participação de todo processo de início de projetos (levantamento de requisitos, reuniões, etc).',
    tools: 'HTML, CSS, React, Api Rest, JavaScript, Bulma, SASS, SCSS.',
  },
  {
    id: 3,
    company: 'GetInfo',
    period: '08/2017 - 08/2018',
    role: 'Estagiária em desenvolvimento Front-end',
    description: 'Desenvolvimento de software com ênfase em front-end.',
    tools: 'HTML, CSS, React, Api Rest, JavaScript, Bulma, SASS, SCSS.',
  },
];

export default experiences;
